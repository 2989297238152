@import "~ngx-smart-modal/ngx-smart-modal";
@import "@fancyapps/ui/dist/fancybox.css";
@font-face {
    font-family: HelveticaNeueLight;
    src: url(assets/fonts/HelveticaNeue-Light.otf) format("opentype");
  }
  @font-face {
    font-family: HelveticaNeueMedium;
    src: url(assets/fonts/HelveticaNeue-Medium.otf) format("opentype");
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  h1,h2,h3,h4,h5,h6,p,a,ul,li,span,ol{
    padding: 0;
    margin: 0;
  }

  body {
    // font-family: "HelveticaNeueLight";
    a {
      text-decoration: none;
    }
    .form-control{
      line-height: 30px;
      &:focus{
        box-shadow: none;
      }
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      display: none;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f16535;
      }

      ::-webkit-scrollbar {
        width: 4px;
        height: 2px;
        background-color: #f16535;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #f5f5f5;
      }
  }


  @media (max-width: 1580px){
    body{
      // zoom: 80%;
    }
  }
  .theme-green .bs-datepicker-head {
    background-color: #f58933 !important;
  }

  .theme-green .bs-datepicker-body table td.week span {
    color: #f58933 !important;
  }
  span.validation_error {
    color: red;
    font-size: 15px;
  }
  .form-select {
    color: #6c757d !important;
  }

  .nsm-dialog {
    max-width: 800px !important;
}

.pagination-box{
  text-align: right;
  padding: 20px 0 0;

  .ngx-pagination {
      .current{
          background: #ff7401;
          border-radius: 5px;
      }
  }
}


.homeCpy{
  .nsm-content{
    // background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    overflow: hidden;
    padding: 35px 15px;
    box-shadow: 0px 11px 18px 0px rgba(175, 149, 121, 0.26);

    .innerBox{
      box-shadow: none !important;
    }
  }
}

.ngx-file-drop__drop-zone {
  border: 2px dashed #bcc1d4 !important;
  border-radius: 10px !important;
  height: auto !important;
  text-align: center; 
  padding: 45px 0px;

  .ngx-file-drop__content {
    height: auto !important;
    display: block !important;

    i {
      font-size: 40px;
      color: #7b84a9;
    }
  }
}